import BlackBird from '~/domains/platform/lib/blackbird'
import { Bucket } from '~/domains/platform/lib/bucket'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'
import { appEnv } from '~/lib/helpers/utils/environment'

export function isDisableByQa() {
  const query = BlackBird.getQuery()
  return (appEnv.isLocal() || appEnv.isSandbox() || appEnv.isCypress()) && Boolean(query.qa)
}

export function isDisableByDevTools() {
  return appEnv.isLocal() && clientSideOnly(() => Bucket.local.get('recaptcha::disabled') === true, false)
}

export function isTestEnv() {
  return isDisableByQa() || isDisableByDevTools()
}

export function additionalQaHeader(headers: Record<string, string> = {}) {
  if (isTestEnv()) {
    return { ...headers, 'x-qa-identity-id': 'BA6CD16D-49B0-4125-957C-E95526EB1E26' }
  }

  return headers
}
