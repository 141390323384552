import { Modal, Text } from '@stone-payments/jade'

import BlackBird from '~/domains/platform/lib/blackbird'

import analitica from '../helpers/analitica-login-events'

interface LoginAccessAttemptingModalProps {
  isOpen: boolean
  onClose(): void
  toggle(): void
}

export function LoginAccessAttemptingModal({ isOpen, toggle, onClose }: LoginAccessAttemptingModalProps) {
  return (
    <Modal
      open={isOpen}
      toggle={toggle}
      onClose={onClose}
      title="Segurança da conta"
      actions={[
        {
          text: 'Não consigo entrar',
          variant: 'primary-solid',
          onClick: () => {
            analitica.events.loginFlow.userGettingTooManyInvalidCredentialsModal('preciso de ajuda')
            BlackBird.travelTo('/nao-consigo-entrar')
          }
        },
        {
          text: 'Voltar',
          variant: 'neutral-subtle',
          onClick: () => {
            onClose()
            analitica.events.loginFlow.userGettingTooManyInvalidCredentialsModal('voltar')
          }
        }
      ]}
    >
      <Text color="medium">
        Após algumas tentativas para entrar, seu acesso pode ser <br /> bloqueado temporariamente.
      </Text>
    </Modal>
  )
}
