import type React from 'react'

import { Choose, For } from '../../lib/utilities-components'
import { Flex } from '../index'

import { Step, Ul } from '../step/step.styles'

interface Props {
  items: { id?: string; content: React.ReactNode }[]
  type?: 'sorted' | 'unordered'
}

export const ItemListing = (props: Props) => {
  const { items = [], type = 'unordered' } = props

  return (
    <Choose>
      <Choose.When condition={type === 'sorted'}>
        <For
          of={items}
          render={(items, index) => (
            <Flex key={index} alignItems="center" aria-label="Item da lista ordenada">
              <Step aria-label={`Item de número ${index + 1}`}>{items.id}</Step>
              {items.content}
            </Flex>
          )}
        />
      </Choose.When>
      <Choose.Otherwise>
        <Ul aria-label="ul">
          <For
            of={items}
            render={(items, index) => (
              <li key={index} aria-label={`Item de número ${index + 1}`}>
                {items.content}
              </li>
            )}
          />
        </Ul>
      </Choose.Otherwise>
    </Choose>
  )
}
