import useWindowWidthBreakpoints from '~/lib/helpers/utils/use-window-width-breakpoints'
import { theme } from '~/ui/theme'

export function useWindowWidthBreakpoint() {
  const breakpoint = useWindowWidthBreakpoints({
    xs: 0 /* isMobile */,
    sm: theme.windowSizes.isTablet /* isTablet 600 */,
    md: theme.windowSizes.isSmallDesktop /* isSmallDesktop 900 */,
    lg: theme.windowSizes.isDesktop /* isDesktop 1280 */,
    xl: theme.windowSizes.isWideScreen /* isWideScreen 1440 */
  })

  return { breakpoint }
}
