import { analiticaInstance } from '~/domains/platform/lib/analitica/analitica.config'

export default analiticaInstance.eventFactory('Login', {
  loginFlow: {
    /**
     * @analitica Quando o usuário clicar em "Seja Stone" deslogado
     */
    pageViewed: () => ({
      label: 'login - page viewed',
      metadata: {
        event: 'Quando o usuário clicar em "Seja Stone" deslogado'
      }
    }),
    /**
     * @analitica Quando tem sucesso depois do challenge e OTP
     */
    successAfterDeviceChallengeAndOTP: () => ({
      label: 'SuccessAfterDeviceChallengeAndOTP',
      metadata: {
        error: { name: 'SuccessAfterDeviceChallengeAndOTP', message: '' }
      }
    }),
    /**
     * @analitica Erro de muitas tentativas de login
     */
    userGettingTooManyInvalidCredentials: () => ({
      label: 'UserGettingTooManyInvalidCredentials',
      metadata: {
        error: { name: 'UserGettingTooManyInvalidCredentials', message: '' }
      }
    }),
    /**
     * @analitica Modal de erro de muitas tentativas de login
     */
    userGettingTooManyInvalidCredentialsModal: (buttonChosen: string) => ({
      label: 'access recovery - start viewed',
      metadata: { buttonChosen }
    }),
    /**
     * @analitica Erro de muitas tentativas de OTP
     */
    userGettingTooManyInvalidOTP: () => ({
      label: 'UserGettingTooManyInvalidOTP',
      metadata: {
        error: { name: 'UserGettingTooManyInvalidOTP', message: '' }
      }
    }),
    /**
     * @analitica Quando tem erro no login (genérico)
     */
    errorOnLogin: (error: Error) => ({
      label: 'error-on-login',
      metadata: {
        error
      }
    })
  },
  loginForm: {
    /**
     * @analitica Quando usuário clica em não consigo logar
     */
    cannotLogin: () => ({
      label: 'access recovery - direct access start viewed'
    })
  }
})
