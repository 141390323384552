import { type PropsWithChildren, type ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Flex } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'
import { useTheme } from '~/ui/hooks/utils/ui/use-theme'
import { useWindowWidthBreakpoint } from '~/ui/hooks/utils/ui/use-window-width-breakpoints'

const Header = styled.header`
  padding: ${theme('space.default')};
`

const Content = styled.article`
  padding: ${theme('space.default')};
`

const Footer = styled.footer`
  padding: ${theme('space.default')};
`

/**
 * @deprecated new InfoContainer in info-container file should be used instead.
 **/
const InfoContainer = ({
  isRightIllustration,
  illustration,
  children
}: PropsWithChildren<{ isRightIllustration?: boolean; illustration?: ReactNode }>) => {
  const { breakpoint } = useWindowWidthBreakpoint()

  const ROW = isRightIllustration ? 'row-reverse' : 'row'
  const COLUMN = isRightIllustration ? 'column-reverse' : 'column'
  const IS_SMALL_DESKTOP = breakpoint.up.md ? ROW : COLUMN

  const {
    sizes: {
      header: { height: headerHeight }
    }
  } = useTheme()

  return (
    <Flex
      flex-wrap="wrap"
      flexDirection={IS_SMALL_DESKTOP}
      justifyContent="center"
      alignItems="center"
      minHeight={`calc(100vh - ${headerHeight})`}
    >
      <If condition={(breakpoint.up.md && isRightIllustration) || !isRightIllustration}>
        <Flex justifyContent="center" alignItems="center" margin="1rem">
          {illustration}
        </Flex>
      </If>
      <Flex justifyContent="center" alignItems="center" margin="1rem">
        <section>{children}</section>
      </Flex>
    </Flex>
  )
}

InfoContainer.Header = (props: { children: JSX.Element | Array<JSX.Element> }) => {
  return <Header>{props.children}</Header>
}

InfoContainer.Content = (props: { children: JSX.Element | Array<JSX.Element> }) => {
  return <Content>{props.children}</Content>
}

InfoContainer.Footer = (props: { children: JSX.Element | Array<JSX.Element> }) => {
  return <Footer>{props.children}</Footer>
}

export { InfoContainer }
