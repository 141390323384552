import { Heading, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { Box, Flex } from '~/domains/platform/design-system'
import {
  LoginDeviceAuthorizationIllustration,
  LoginDeviceSmallMobileAuthorizationIllustration
} from '~/domains/platform/design-system/illustrations/login-device-challenge-message.illustrations'

export const Background = styled(Flex)`
  background-color: ${theme('colors.mediumGray4')};
  margin: ${theme('space.xLarge')};
  padding: ${theme('space.xLarge')};
  border-radius: 0 0 ${theme('space.medium')} ${theme('space.medium')};
  max-width: 32rem;
  * > svg {
    max-width: 40rem;
  }
`

export const WrapperDevice = styled(Box)`
  padding-right: ${theme('space.large')};
  h3 {
    margin-bottom: ${theme('space.small')};
  }
`

export function LoginDeviceChallengeMessage(props: { device?: string }) {
  return (
    <Flex flexDirection="column" alignItems="center">
      <LoginDeviceAuthorizationIllustration />
      <Background>
        <WrapperDevice>
          <Heading weight="bold" size="small">
            Aviso de autorização não apareceu?
          </Heading>
          <Text as="p" size="large">
            Com o aplicativo da Stone aberto no dispositivo principal&nbsp;
            {props.device}, toque em início e arraste a tela pra baixo
          </Text>
        </WrapperDevice>
        <LoginDeviceSmallMobileAuthorizationIllustration />
      </Background>
    </Flex>
  )
}
