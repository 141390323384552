import { Modal } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

import { type DialogModalProps } from './interface'

export const StyledDialogModal = styled(Modal)<DialogModalProps>`
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: ${theme('zIndices.modal')};
  width: ${ifProp('width', prop('width'))};

  animation: 0.4s fade-in ease-in;
  @keyframes modal-in {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }
`
