import { RECAPTCHA_SITE_KEY } from '~/lib/constants'

declare global {
  interface Window {
    GRecaptcha: any
  }
}

export async function getCaptcha(): Promise<string> {
  /**
   * This implementation was based on the link below.
   * @link https://cloud.google.com/recaptcha-enterprise/docs/instrument-web-pages?hl=pt-br&cloudshell=false
   * @disclaimer GRecaptcha object is set at the _app.tsx
   */
  return await globalThis.window.GRecaptcha.execute(RECAPTCHA_SITE_KEY(), {
    action: 'LOGIN'
  })
}
