import { type PropsWithChildren } from 'react'
import FocusLock from 'react-focus-lock'
import { useLockBodyScroll } from 'react-use'

import { Overlay } from '~/domains/platform/design-system/overlay/overlay'
import { If } from '~/domains/platform/lib/utilities-components'
import { theme } from '~/ui/theme'

import { type DialogModalProps } from './interface'
import { StyledDialogModal } from './styles'

export const DialogModal = ({ isOpen, width, ...props }: PropsWithChildren<DialogModalProps>) => {
  useLockBodyScroll(isOpen)
  return (
    <If condition={isOpen}>
      <FocusLock>
        <Overlay zIndex={theme.zIndices.topMenu} />
        <StyledDialogModal isOpen={isOpen} width={width} {...props} />
      </FocusLock>
    </If>
  )
}
