import { useFormContext } from 'react-hook-form'
import { Grid } from '@dlpco/fluid-layout'
import { Alert, Button, InputField } from '@stone-payments/jade'

import { Box } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

interface OtpFormProps {
  reset: () => void
  hasError: boolean
}

export const LoginOtpForm = ({ reset, hasError }: OtpFormProps) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<{ totp: string }>()
  return (
    <Box py={2}>
      <Box py={3}>
        <InputField
          label="Código de autenticação de 2 fatores"
          id="totp"
          {...register('totp', {
            required: 'Campo obrigatório'
          })}
          error={Boolean(errors.totp)}
          supportText={errors?.totp?.message}
        />
      </Box>
      <If condition={hasError}>
        <Box mb={3}>
          <Alert variant="negative" showIcon description="Código de autorização inválido. Tente novamente." />
        </Box>
      </If>
      <Grid min="10%" gutter="1rem">
        <Button size="large" variant="primary-solid" type="submit">
          Entrar
        </Button>

        <Button color="neutral" size="large" onClick={reset}>
          Cancelar
        </Button>
      </Grid>
    </Box>
  )
}
