import { useFormContext } from 'react-hook-form'
import { Stack } from '@dlpco/fluid-layout'
import { Alert, Button, InputField, Link } from '@stone-payments/jade'

import { Box } from '~/domains/platform/design-system'
import { JadePasswordInput } from '~/domains/platform/design-system/password-input/password-input'
import BlackBird from '~/domains/platform/lib/blackbird'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'

import analitica from '../helpers/analitica-login-events'

interface LoginFormProps {
  hasLoginError: boolean
  setHasLoginError: React.Dispatch<React.SetStateAction<boolean>>
  tryWithEmail: boolean
}

type LoginFormData = {
  username: string
  password: string
}

export const LoginForm = ({ hasLoginError, setHasLoginError, tryWithEmail }: LoginFormProps) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<LoginFormData>()

  const handleCPF = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hasLoginError) setHasLoginError(false)

    e.currentTarget.value = stringFormat.usernameFormatter(e.currentTarget.value)
  }

  const usernameFormRegistration = register('username')
  const passwordFormRegistration = register('password')

  const goToCannotLogin = () => {
    if (!hasLoginError) {
      analitica.events.loginForm.cannotLogin()
    }
    BlackBird.travelTo('/nao-consigo-entrar')
  }

  return (
    <Stack space="1rem">
      <If
        condition={hasLoginError}
        render={() => (
          <Box maxWidth="21rem" pt="1rem">
            <Choose>
              <Choose.When condition={tryWithEmail}>
                <Alert
                  variant="warning"
                  showIcon
                  description="Confira se os dados estão certos. Se não conseguir entrar, use o e-mail no lugar do CPF."
                />
              </Choose.When>
              <Choose.Otherwise>
                <Alert
                  variant="warning"
                  showIcon
                  description="Confira se os dados estão certos. Se precisar, clique em Esqueci minha senha."
                />
              </Choose.Otherwise>
            </Choose>
          </Box>
        )}
      />
      <InputField
        id="username"
        role="textbox"
        aria-label="usuário"
        label="E-mail ou CPF"
        placeholder="Digite seu e-mail ou CPF"
        error={Boolean(errors.username)}
        supportText={errors.username?.message}
        {...usernameFormRegistration}
        onChange={e => {
          handleCPF(e)
          usernameFormRegistration.onChange(e)
        }}
        onBlur={e => {
          handleCPF(e)
          usernameFormRegistration.onBlur(e)
        }}
      />

      <JadePasswordInput
        id="password"
        type="password"
        role="textbox"
        aria-label="senha"
        label="Senha"
        placeholder="Digite sua senha"
        error={Boolean(errors.password)}
        supportText={errors.password?.message}
        {...passwordFormRegistration}
        onChange={e => {
          if (hasLoginError) setHasLoginError(false)
          passwordFormRegistration.onChange(e)
        }}
      />

      <Box display="flex" pb="0.5rem">
        <Link size="large" onClick={goToCannotLogin}>
          Esqueci minha senha
        </Link>
      </Box>

      <Button type="submit" size="large" variant="primary-solid">
        Entrar
      </Button>
    </Stack>
  )
}
