import { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useEffectOnce, useInterval } from 'react-use'
import { Stack } from '@dlpco/fluid-layout'
import { Display, Heading, Text } from '@dlpco/ginga-stone'
import dayjs from 'dayjs'

import { Box } from '~/domains/platform/design-system'
import { ItemListing } from '~/domains/platform/design-system/item-listing/item-listing'
import { logger } from '~/domains/platform/infra/monitoring/logger'
import { InfoContainer } from '~/domains/stone-account/shared/components/information-container'
import { SIGN_IN_CHALLENGE_PENDING_APPROVAL } from '~/lib/constants'
import { trackEvents } from '~/lib/helpers/utils/ports/analytics'
import { TopBarLayout } from '~/ui/components/layouts/top-bar-layout'
import { useRequest } from '~/ui/hooks/utils/service/use-request'

import { LoginDeviceChallengeMessage } from './login-device-challenge-message'

interface DeviceChallengeProps {
  assertion: string
  primaryDeviceModel: string
  onSuccess(props: { assertion: string; assertionResponse: string }): void
  onError(type: string): void
}

export function LoginDeviceChallenge({ assertion, primaryDeviceModel, onSuccess, onError }: DeviceChallengeProps) {
  const [startDate] = useState(dayjs())
  const NAME_DEVICE = primaryDeviceModel

  const getElapsedTime = useCallback(() => {
    return dayjs().diff(startDate, 'second')
  }, [startDate])

  const { data } = useRequest<{ assertionResponse: string }, { type: string }>(
    { url: `/users/signin/challenges/${assertion}` },
    {
      shouldRetryOnError: true,
      onErrorRetry(error, __, _, revalidate, { retryCount }) {
        if (error?.response?.data.type === SIGN_IN_CHALLENGE_PENDING_APPROVAL) {
          setTimeout(() => revalidate({ retryCount: (retryCount ?? 0) + 1 }), 8000)

          return
        }

        if (error?.response?.status === 429) {
          logger({
            name: 'UserBlockedByCloudFlareException',
            message: `User is blocked by firewall for ${retryCount} challenges`
          })
        }

        logger(error)
        onError(error?.response?.data.type as string)
      }
    }
  )

  useInterval(() => {
    logger({
      name: 'UserWaitingTooLongToAnswerDeviceChallengeException',
      message: `User is in the device challenge screen for ${getElapsedTime() / 60} minutes`
    })

    trackEvents({
      trackServices: ['analytics'],
      action: 'user_waiting_too_long_device_challenge',
      value: getElapsedTime(),
      category: 'Login'
    })
  }, 50000)

  useInterval(() => {
    trackEvents({
      trackServices: ['analytics'],
      action: 'awaiting_device_challenge_screen',
      value: getElapsedTime(),
      category: 'Login'
    })
  }, 10000)

  useEffectOnce(() => {
    ReactGA.modalview(`/login/desafio-dispositivo`)
  })

  useEffect(() => {
    if (data) {
      const { assertionResponse } = data

      if (assertionResponse) {
        const elapsedTime = getElapsedTime()

        trackEvents({
          trackServices: ['analytics'],
          category: 'Login',
          action: 'user_success_in_device_challenge',
          value: elapsedTime
        })

        if (elapsedTime <= 10) {
          trackEvents({
            trackServices: ['analytics'],
            category: 'Login',
            action: 'user_answer_too_fast_device_challenge',
            value: getElapsedTime()
          })

          logger({
            name: 'UserAnswerTooFastDeviceChallengeException',
            message: `User took ${getElapsedTime()} seconds to approve challenge`
          })
        }

        if (elapsedTime >= 30) {
          trackEvents({
            trackServices: ['analytics'],
            category: 'Login',
            action: 'user_answer_too_long_device_challenge',
            value: getElapsedTime()
          })

          logger({
            name: 'UserAnswerTooLongDeviceChallengeException',
            message: `User took ${getElapsedTime()} seconds to approve challenge`
          })
        }

        onSuccess({ assertion, assertionResponse })
      }
    }
  }, [data, getElapsedTime, assertion, onSuccess])

  return (
    <TopBarLayout>
      <Box backgroundColor="white">
        <InfoContainer illustration={<LoginDeviceChallengeMessage device={NAME_DEVICE} />}>
          <InfoContainer.Header>
            <Stack space="3rem">
              <Box style={{ maxWidth: '40ch' }}>
                <Display as="h1" size="medium" weight="bold">
                  Autorização de dispositivo
                </Display>
              </Box>
              <Box style={{ maxWidth: '60ch' }}>
                <Heading size="large">Pra autorizar, mantenha esta tela aberta e siga o passo a passo.</Heading>
              </Box>
            </Stack>
          </InfoContainer.Header>
          <InfoContainer.Content>
            <ItemListing
              type="sorted"
              items={[
                {
                  id: '1',
                  content: (
                    <Text as="span" size="large">
                      Abra o aplicativo da Stone no&nbsp;
                      <Text as="span" size="large" weight="bold">
                        {NAME_DEVICE}
                      </Text>
                      .
                    </Text>
                  )
                },
                {
                  id: '2',
                  content: (
                    <Text as="span" size="large">
                      Toque em cima do aviso de&nbsp;
                      <Text as="span" size="large" weight="bold">
                        autorização na tela inicial
                      </Text>
                      , ele vai ficar visível por 5 minutos
                    </Text>
                  )
                },
                {
                  id: '3',
                  content: (
                    <Text as="span" size="large">
                      Na próxima tela, toque em&nbsp;
                      <Text as="span" size="large" weight="bold">
                        autorizar
                      </Text>
                    </Text>
                  )
                }
              ]}
            />
          </InfoContainer.Content>
        </InfoContainer>
      </Box>
    </TopBarLayout>
  )
}
