import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useCounter, useList, useToggle } from 'react-use'
import Image from 'next/image'
import NextLink from 'next/link'
import { Box as JadeBox, Link, Spinner, Text } from '@stone-payments/jade'
import { type AxiosError } from 'axios'

import { authenticator } from '~/domains/platform/core/authenticator/authenticator'
import { fetchTokenFlags } from '~/domains/platform/core/token-flags/fetch-token-flags'
import { Box, Flex } from '~/domains/platform/design-system'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { useToast } from '~/domains/platform/layout/toast'
import BlackBird from '~/domains/platform/lib/blackbird'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'
import { Choose, If } from '~/domains/platform/lib/utilities-components'
import { additionalQaHeader } from '~/domains/stone-account/auth/shared/helpers/disable-identity'
import { getCaptcha } from '~/domains/stone-account/auth/shared/services/get-captcha'
import { EMAIL_REGEX } from '~/lib/constants'
import {
  INVALID_PARAMS_ERROR,
  MAINTENANCE_ERROR,
  MISSING_TOTP_ERROR,
  SIGN_IN_CHALLENGE_DENIED,
  SIGN_IN_CHALLENGE_EXPIRED,
  SIGN_IN_CHALLENGE_REQUIRED,
  UNAUTHENTICATED_ERROR
} from '~/lib/constants/errors'
import { trackEvents, unmask } from '~/lib/helpers'
import { CookieConsent } from '~/ui/business-components'
import { CleanPageContainer } from '~/ui/containers'

// eslint-disable-next-line @nx/enforce-module-boundaries
import Logo from '../../../../../../../public/logo-stone.svg'
import analitica from '../helpers/analitica-login-events'

import { LoginAccessAttemptingModal } from './login-access-attempting-modal'
import { LoginAuthorizationModal } from './login-authorization-modal'
import { LoginDeviceChallenge } from './login-device-challenge'
import { LoginForm } from './login-form'
import { LoginOtpForm } from './login-otp-form'

type LoginFormData = {
  username: string
  password: string
  totp: string
}

export function LoginFlow() {
  const methods = useForm<LoginFormData>()
  const [invalidCredentialsCount, { inc: incrementInvalidCredentialsCount }] = useCounter(0)
  const [invalidOTPCount, { inc: incrementInvalidOTPCount }] = useCounter(0)
  const [challengesAlreadyRequired, challengesAlreadyRequiredActions] = useList()
  const [hasError, setHasError] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [showOtp, toggleOtp] = useToggle(false)
  const [showDeviceChallenge, toggleDeviceChallenge] = useToggle(false)
  const [showModalAuthorization, toggleModalAuthorization] = useToggle(false)
  const [showAccessAttemptingsModal, toggleAccessAttemptingsModal] = useToggle(false)
  const [showLoading, setShowLoading] = useState(false)
  const [assertion, setAssertion] = useState<string>()
  const [primaryDeviceModel, setPrimaryDeviceModel] = useState<string>()

  const { addToast } = useToast()

  const query = BlackBird.getQuery()

  const handleResetForm = () => {
    toggleOtp(false)
    setHasError(false)
    setShowLoading(false)
    setFormValues({})
    methods.reset()
  }

  const onSubmit = async (values: any) => {
    const currentValues = { ...formValues, ...values }

    setFormValues(currentValues)

    try {
      setShowLoading(true)

      const assertionResponse = currentValues?.assertionResponse

      const captcha = await getCaptcha()

      await authenticator.login({
        ...currentValues,
        assertion_response: currentValues.assertionResponse,
        username: currentValues.username.includes('@') ? currentValues.username : unmask(currentValues.username),
        password: currentValues.password,
        captcha: !assertionResponse ? captcha : undefined,
        headers: additionalQaHeader()
      })

      if (challengesAlreadyRequired.includes('OTP') && challengesAlreadyRequired.includes('DEVICE')) {
        analitica.events.loginFlow.successAfterDeviceChallengeAndOTP()
        trackEvents({
          action: 'user_success_in_device_challenge_and_otp',
          category: 'login',
          trackServices: ['analytics']
        })
      }

      const tokenFlags = await fetchTokenFlags()

      if (tokenFlags.includes('entity_account_list')) {
        if (query.redirectUrl) BlackBird.select({ redirectUrl: decodeURIComponent(query.redirectUrl as string) })
        else BlackBird.select()
      } else {
        if (query.redirectUrl) BlackBird.redirect(decodeURIComponent(query.redirectUrl as string))
        else BlackBird.goHome()
      }
    } catch (err) {
      const error = err as AxiosError | Error | any

      setShowLoading(false)

      if (error.status === 401 && error.body && error.body.type === UNAUTHENTICATED_ERROR) {
        setHasError(true)

        if (!showOtp) {
          incrementInvalidCredentialsCount()

          if (invalidCredentialsCount >= 2) {
            toggleAccessAttemptingsModal(true)

            analitica.events.loginFlow.userGettingTooManyInvalidCredentials()
          }
        }

        if (showOtp) {
          incrementInvalidOTPCount()

          if (invalidOTPCount >= 2) {
            analitica.events.loginFlow.userGettingTooManyInvalidOTP()
          }
        }

        return
      }

      if (error.status === 403 && error.body && error.body.type === SIGN_IN_CHALLENGE_REQUIRED) {
        setAssertion(error.body.assertion)
        setPrimaryDeviceModel(error.body['primary_device_model'])
        toggleDeviceChallenge(true)

        challengesAlreadyRequiredActions.push('DEVICE')
        return
      }

      if (error.status === 401 && error.body && error.body.type === MISSING_TOTP_ERROR) {
        setHasError(false)
        toggleDeviceChallenge(false)
        toggleOtp(true)

        challengesAlreadyRequiredActions.push('DEVICE')
        return
      }

      if (error.status === 422 && error.body && error.body.type === INVALID_PARAMS_ERROR) {
        methods.setError('username', {
          type: 'async',
          message: 'Não esqueça seu e-mail ou CPF'
        })

        methods.setError('password', {
          type: 'async',
          message: 'Não esqueça sua senha'
        })

        return
      }

      if (error.status === 429) {
        BlackBird.travelTo('/bloqueado')

        return
      }

      if (error.status === 503 && error.body.type === MAINTENANCE_ERROR) {
        BlackBird.travelTo('/fora-de-servico')

        return
      }

      analitica.events.loginFlow.errorOnLogin(error)
      setHasError(true)
    }
  }

  return (
    <Choose>
      <Choose.When
        condition={showDeviceChallenge}
        render={() => (
          <>
            <If condition={showModalAuthorization}>
              <LoginAuthorizationModal
                onClose={() => {
                  toggleModalAuthorization(false)
                  toggleDeviceChallenge(false)
                }}
              />
            </If>
            <LoginDeviceChallenge
              onSuccess={({ assertion, assertionResponse }) => {
                toggleDeviceChallenge(false)
                onSubmit({ ...formValues, assertion, assertionResponse })
              }}
              onError={type => {
                if (type === SIGN_IN_CHALLENGE_DENIED) {
                  toggleModalAuthorization(true)
                  return
                }

                toggleDeviceChallenge(false)

                if (type === SIGN_IN_CHALLENGE_EXPIRED) {
                  addToast({ message: 'A Sessão expirou. Tente novamente', type: 'error' })
                  return
                }

                if (type === UNAUTHENTICATED_ERROR) {
                  addToast({ message: 'Nome de usuário e/ou senha inválidos.', type: 'error' })
                }

                addToast({
                  message: 'Oops, algo inesperado aconteceu. Tente novamente mais tarde.',
                  type: 'error'
                })
              }}
              assertion={String(assertion)}
              primaryDeviceModel={String(primaryDeviceModel)}
            />
          </>
        )}
      />
      <Choose.Otherwise
        render={() => (
          <>
            <CleanPageContainer>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} method="POST">
                  <JadeBox hasPadding>
                    <Flex pb={3} justifyContent="center" alignItems="center">
                      <Image src={Logo} alt="Stone Logo" />
                    </Flex>

                    <Dimmer isVisible={showLoading}>
                      <Flex justifyContent="center" alignItems="center">
                        <Spinner size="xlarge" color="brand" />
                      </Flex>
                    </Dimmer>

                    <Choose>
                      <Choose.When
                        condition={showOtp}
                        render={() => <LoginOtpForm reset={handleResetForm} hasError={hasError} />}
                      />
                      <Choose.When
                        condition={showAccessAttemptingsModal}
                        render={() =>
                          clientSideOnly(() => (
                            <LoginAccessAttemptingModal
                              isOpen={showAccessAttemptingsModal}
                              toggle={toggleAccessAttemptingsModal}
                              onClose={() => toggleAccessAttemptingsModal(false)}
                            />
                          ))
                        }
                      />
                      <Choose.Otherwise
                        render={() => (
                          <LoginForm
                            tryWithEmail={!EMAIL_REGEX.test(methods.getValues('username'))}
                            hasLoginError={hasError}
                            setHasLoginError={setHasError}
                          />
                        )}
                      />
                    </Choose>
                  </JadeBox>
                  <Flex mt={5} flexDirection="column">
                    <Box display="flex" justifyContent="center">
                      <Text>Ainda não é Stone?&nbsp;</Text>
                      <NextLink href="https://www.stone.com.br/adquira/" passHref legacyBehavior>
                        <Link
                          target="blank_"
                          rel="noopener noreferrer"
                          onClick={() => analitica.events.loginFlow.pageViewed()}
                        >
                          Seja Stone!
                        </Link>
                      </NextLink>
                    </Box>
                  </Flex>
                </form>
              </FormProvider>
            </CleanPageContainer>
            <CookieConsent />
          </>
        )}
      />
    </Choose>
  )
}
