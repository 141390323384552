import { Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import { DialogModal } from '~/ui/components/modals/dialog-modal'

export const TextWrapper = styled.div`
  max-width: 28rem;
  color: ${theme('colors.mediumGray')};
`

interface AuthorizationModalProps {
  onClose(): void
}

export function LoginAuthorizationModal({ onClose }: AuthorizationModalProps) {
  return (
    <DialogModal
      isOpen
      variant="warning"
      heading="Acesso não autorizado"
      actions={[{ label: 'Fechar', color: 'neutral' }]}
      onAction={onClose}
    >
      <TextWrapper>
        <Text color="neutral">&nbsp;O dispositivo principal não autorizou este acesso.</Text>
        <Text color="neutral">&nbsp;Entre novamente pra pedir uma nova autorização.</Text>
      </TextWrapper>
    </DialogModal>
  )
}
