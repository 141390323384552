import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Flex } from '../index'

export const Step = styled(Flex)<{ solid?: boolean }>`
  color: ${theme('colors.primary')};
  font-weight: bold;
  font-size: 16px;
  margin: ${theme('space.default')} ${theme('space.default')} ${theme('space.default')} 0;
  min-width: ${theme('space.large')};
  height: ${theme('space.large')};
  border-radius: ${theme('radii.circle')};
  border: 1px solid ${theme('colors.primary')};
  align-items: center;
  justify-content: center;

  ${ifProp(
    'solid',
    css`
      color: ${theme('colors.white')};
      background: ${theme('colors.primary')};
    `
  )}
`

export const Ul = styled.ul`
  list-style: none;
  padding-left: 1em;

  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: 1rem;
  }

  li {
    position: relative;
  }

  li::before {
    content: '•';
    color: ${theme('colors.primary')};
    font-size: 1em;
    display: inline-block;
    position: absolute;
    left: -1em;
  }
`
